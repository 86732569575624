export const menulist = [
  // {
  //   keyIndex: 1,
  //   link: "/",
  //   routerName: "home",
  //   name: "Home",
  //   icon: "home",
  // },
  // {
  //   keyIndex: 2,
  //   subMenuIcon: "chevron-down",
  //   subMenuTitle: "Funding Plans",
  //   subMenuList: [
  //     {
  //       keyIndex: 3,
  //       link: "/2-step",
  //       routerName: "2-step",
  //       name: "2 - Step",
  //       icon: "grid",
  //     },
  //     {
  //       keyIndex: 4,
  //       link: "/1-step",
  //       routerName: "1-step",
  //       name: "1 - Step",
  //       icon: "grid",
  //     },
  //     {
  //       keyIndex: 5,
  //       link: "/direct",
  //       routerName: "direct",
  //       name: "Direct",
  //       icon: "grid",
  //     },
  //     {
  //       keyIndex: 6,
  //       link: "/scaling",
  //       routerName: "scaling",
  //       name: "Scaling",
  //       icon: "grid",
  //     },
  //   ],
  // },
  {
    keyIndex: 30,
    link: "/user",
    routerName: "user",
    name: "User",
    icon: "users",
    permissions: [
      "super_admin",
    ],
  },
  {
    keyIndex: 12,
    link: "/leaderboard",
    routerName: "leaderboard",
    name: "Leaderboard",
    icon: "sliders",
    permissions: [
      "admin"
    ],
  },
  {
    keyIndex: 13,
    link: "/affiliate",
    routerName: "affiliate",
    name: "Affiliate",
    icon: "grid",
    permissions: [
      "admin"
    ],
  },
  // {
  //   keyIndex: 14,
  //   link: "/footer",
  //   routerName: "footer",
  //   name: "Footer",
  //   icon: "sidebar",
  // },
  {
    keyIndex: 15,
    subMenuIcon: "book",
    subMenuTitle: "Blogs",
    permissions: [
      "admin"
    ],
    subMenuList: [
      {
        keyIndex: 16,
        link: "/blog",
        routerName: "blog",
        name: "Blog",
        icon: "book",
      },
      {
        keyIndex: 17,
        link: "/blog/category",
        routerName: "blog-category",
        name: "Category",
        icon: "list",
      },
      {
        keyIndex: 18,
        link: "/blog/tag",
        routerName: "blog-tag",
        name: "Tag",
        icon: "tag",
      },
    ],
  },
  {
    keyIndex: 23,
    subMenuIcon: "video",
    subMenuTitle: "Prime Academy",
    permissions: [
      "admin"
    ],
    subMenuList: [
      {
        keyIndex: 24,
        link: "/lesson",
        routerName: "lesson",
        name: "Lesson",
        icon: "video",
      },
      {
        keyIndex: 25,
        link: "/lesson/category",
        routerName: "lesson-category",
        name: "Category",
        icon: "list",
      },
    ],
  },
  {
    keyIndex: 27,
    subMenuIcon: "folder",
    subMenuTitle: "FAQs",
    permissions: [
      "admin"
    ],
    subMenuList: [
      {
        keyIndex: 28,
        link: "/faq",
        routerName: "faq",
        name: "FAQs",
        icon: "folder",
      },
      {
        keyIndex: 29,
        link: "/faq/category",
        routerName: "faq-category",
        name: "Category",
        icon: "list",
      },
    ],
  },
  {
    keyIndex: 19,
    link: "/images",
    routerName: "images",
    name: "Images",
    icon: "image",
    permissions: [
      "admin"
    ],
  },
  {
    keyIndex: 22,
    link: "/lp",
    routerName: "lp",
    name: "Marketing Pages",
    icon: "package",
    permissions: [
      "admin"
    ],
  },
  {
    keyIndex: 23,
    link: "/transparency-report",
    routerName: "transparency-report",
    name: "Transparency Re..",
    icon: "book",
    permissions: [
      "admin"
    ],
  },
  {
    keyIndex: 26,
    link: "/contact-inquiry",
    routerName: "contact-inquiry",
    name: "Contact Inquiry",
    icon: "mail",
    permissions: [
      "admin"
    ],
  },
  {
    keyIndex: 7,
    subMenuIcon: "box",
    subMenuTitle: "Certifier",
    permissions: [
      "admin"
    ],
    subMenuList: [
      {
        keyIndex: 10,
        link: "/certifier/design",
        routerName: "certifier-design",
        name: "Designs",
        icon: "pen-tool",
      },
      {
        keyIndex: 8,
        link: "/certifier/group",
        routerName: "certifier-group",
        name: "Groups",
        icon: "cloud",
      },
      {
        keyIndex: 9,
        link: "/certifier/credential",
        routerName: "certifier-credential",
        name: "Credentials",
        icon: "list",
      },
    ],
  },
];